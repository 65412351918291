<template>
    <div>
        <div class="app-modal__box">
            <div class="app-modal__in">
                <div
                    class="app-modal__header d-flex f-between p-5 m-title-modal"
                >
                    <p class="large--title m-0"> {{ $t('message.task') }}  №{{ model.id }} {{ model.name }}</p>
                    <div>
                        <el-button type="warning" size="medium" @click="close()" plain>{{ $t('message.cancel') }} </el-button>
                    </div>
                </div>
            </div>
            <!-- app-modal__header end -->

            <div class="app-modal__body p-5 pb-0">
                <div class="timeline-items__right rounded-sm w-100 p-4">
                    <el-row :gutter="30">
                        <el-col :span="16">
                            <div class="t-title">
                                <i class="el-icon-tickets"></i>
                                {{ $t('message.task') }}  №{{ model.id }}
                            </div>

                            <div class="user-metca mt-5">
                                <div class="gc-card-users__info mt-4 mr-3" >
                                    <span class="font-bold mb-2 d-block"> {{ $t('message.participants') }}  </span>

                                    <div class="users__info">
                                    <div class="task_user_give">
                                        <div class="itme-userss">
                                            <el-popover
                                                placement="top"
                                                popper-class="p-0 myir"
                                                width="300"
                                                trigger="hover">
                                                <div class="click-block">
                                                    <div class="top-u-click">
                                                        <img class="task_user_give_img" :src="model.user ? model.user.avatar : '/img/flag/employee.svg'" alt="">
                                                        <div class="tt-name">
                                                            <div>{{model.user ? model.user.name : '' }}</div>
                                                            <span>{{model.user ? (model.user.email ?  model.user.email : '') : ''}}</span>
                                                        </div>
                                                    </div>
                                                    <ul>
                                                        <li>
                                                            <span><i class="el-icon-warning-outline"></i>ID: {{ model.user ? model.user.id : '' }}</span>
                                                        </li>
                                                        <li>
                                                            <span><i class="el-icon-mobile-phone"></i>Tel: {{ model.user ? model.user.phone : ''}}</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                                
                                                <el-button slot="reference">
                                                    <img class="task_user_give_img" :src="model.user ? model.user.avatar : '/img/flag/employee.svg'" alt="">
                                                </el-button>
                                            </el-popover>
                                        </div>
                                    </div>
                                    <i class="el-icon-arrow-right ml-1 mr-1" v-if="model.participants && model.participants.length > 0"></i>
                                    <div class="d-flex task_user_accept" v-if="model.participants && model.participants.length > 0">
                                        <div class="itme-userss">
                                            <el-popover
                                                placement="top"
                                                popper-class="p-0 myir"
                                                width="300"
                                                v-for="participant in model.participants" :key="participant.id"
                                                trigger="hover">
                                                <div class="click-block">
                                                    <div class="top-u-click">
                                                        <img class="task_user_give_img" :src="participant ? participant.avatar : '/img/flag/employee.svg'" alt="">
                                                        <div class="tt-name">
                                                            <div>{{ participant ? participant.name : ''}}</div>
                                                            <span>{{ participant ? (participant.email ? participant.email : '') : ''}}</span>
                                                        </div>
                                                    </div>
                                                    <ul>
                                                        <li>
                                                            <span><i class="el-icon-warning-outline"></i>ID: {{  participant ? participant.id : '' }}</span>
                                                        </li>
                                                        <li>
                                                            <span><i class="el-icon-mobile-phone"></i>Tel: {{ participant ? participant.phone : ''}}</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                                
                                                <el-button slot="reference">
                                                    <img class="task_user_give_img" :src="participant ? participant.avatar : '/img/flag/employee.svg'" alt="">
                                                </el-button>
                                            </el-popover>
                                        </div>
                                    </div>
                                    </div>
                                </div>

                                <div class="u-clearfix js-card-detail-labels-list mt-4" v-if="model.labels && model.labels.length > 0">
                                    <div class="font-bold mb-2 d-block">{{ $t('message.labels') }} </div>

                                    <span
                                        class="card-label mod-card-detail"
                                        title=""
                                        :class="label.color_id ? 'color_'+label.color_id : 'color_1'"
                                        v-for="label in model.labels" :key="label.id"
                                        >
                                    <span class="label-text"> {{ label.name }}</span>
                                    </span>

                                </div>
                            </div>

                            <div class="left-tt mt-5">
                                <div class="title">{{ $t('message.deadline') }}  :</div>
                                <div v-if="model.begin_date">
                                    {{ model.begin_date }}
                                    <i class="el-icon-right"></i>
                                    {{ model.end_date }}
                                </div>
                                <div v-else>
                                    {{ $t('message.no_deadline') }} 
                                </div>
                            </div>

                            <div class="left-tt mt-5">
                                <div class="title">{{ $t('message.date') }}  :</div>
                                <div>
                                    <i class="el-icon-date"></i>
                                    {{ model.finish_date ?  model.finish_date : $t('message.without_finish_date') }}
                                </div>
                            </div>

                            <div class="left-tt mt-5" v-for="(comment, index) in model.comments" :key="'comment' + comment.id">
                                <div class="title"> {{ $t('message.description') }}: {{index + 1}}</div>
                                <div>
                                    {{ comment.comment + comment.id}}
                                </div>
                            </div>

                            <div class="left-tt mt-5" v-for="checkList in model.checkLists" :key="'checkList' + checkList.id">
                                <div class="title">{{ $t('message.working') }}: {{ checkList.name }}</div>
                                <div v-for="miniTask in checkList.miniTasks" :key="'miniTask' + miniTask.id">
                                    <i :class="miniTask.is_done ? 'el-icon-check' : 'el-icon-close'"></i>
                                    {{ miniTask.text }}
                                </div>
                            </div>

                            
                        </el-col>
                        <el-col :span="8" v-if="model.deal">
                            <div class="t-title">
                                <i class="el-icon-tickets"></i>
                                {{ $t('message.deal') }}
                            </div>

                            <div class="right-section">
                                <div>{{ $t('message.company') }}</div>
                                <span>{{model.company ? model.company.name : ''}}</span>
                            </div>

                            <div class="right-section">
                                <div>{{ $t('message.client') }}</div>
                                <span>{{model.client ? model.client.full_name : ''}}</span>
                            </div>

                            <div class="right-section" v-if="model.deal">
                                <div>{{ $t('message.deal') }}</div>
                                <span> {{model.deal ? model.deal.name : ''}}</span>
                            </div>
                        </el-col>
                    </el-row>
                    <div class="mt-5">
                        <el-row class="mt-5">
                            <el-button
                                type="warning"
                                @click="activateTask(model.id)"
                                >{{ $t('message.restore') }} </el-button
                            >
                            <el-button type="danger" @click="deleteTask(model.id)"
                                >{{ $t('message.delete_completely') }}</el-button
                            >
                        </el-row>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import show from "@/utils/mixins/show";
export default {
    mixins: [form, drawer, show],
    name: "task",
    data() {
        return {
            activeName: "first",
        };
    },
    computed: {
        ...mapGetters({
            model: "tasks/model",
            columns: "tasks/columns",
        }),
    },
    methods: {
        ...mapActions({
            show: "tasks/show",
            destroy: "tasks/destroy",
            restoreTask: "tasks/restoreTask"
        }),
        activateTask(id){
            this.$confirm(
                this.$t('message.do_you_really_want_to_do_this'),
                this.$t('message.warning'), {
                    confirmButtonText: this.$t('message.yes'),
                    cancelButtonText: this.$t('message.no'),
                    type: "warning"
                }
            )
            .then(() => {
               this.restoreTask(id)
                        .then((res) =>{
                            this.$alert(res);
                            this.close(true);
                            this.parent().fetchData()
                        })
                        .catch((err) => {
                            this.$alert(err);
                        })
            })
            .catch(() => {
              this.$message({
                type: "warning",
                message: this.$t('message.operation_canceled')
              });
            });
        },
        deleteTask(id){
           this.$confirm(
                this.$t('message.do_you_really_want_to_do_this'),
                this.$t('message.warning'), {
                    confirmButtonText: this.$t('message.yes'),
                    cancelButtonText: this.$t('message.no'),
                    type: "warning"
                }
            )
            .then(() => {
              this.destroy(id)
              .then(res => {
                  this.$alert(res);
                  this.close(true);

                  this.parent().fetchData()
              })
            })
            .catch(() => {
              this.$message({
                type: "warning",
                message: this.$t('message.operation_canceled')
              });
            });
        }
    },
};
</script>
